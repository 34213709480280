/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        // JavaScript to be fired on all pages
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');

      /*
      Custom Scripts
       */

      // sliders
      $("#hero").slidesjs({
        width: 1600,
        height: 500,
        navigation: {
          active: false
        },
        pagination: {
          active: false
        },
        play: {
          auto: true
        }
      });

      // mobile nav
      var mobileToggle = $('.mobile_nav_toggle'),
        mainNav = $('.main_nav'),
        navAnchors = $('.main_nav > li');

      mobileToggle.on('click', function (e) {
        e.preventDefault();
        $('.main_nav_no_circles').slideToggle();
      });

      navAnchors.hover(function (e) {
        if (!mobileToggle.is(':visible')) {
          $(this).children('ul').stop().slideDown();
        }
      }, function (e) {
        if (!mobileToggle.is(':visible')) {
          $(this).children('ul').stop().slideUp();
        }
      });

      // home hero
      homeHeroImages = $('.home_hero_no_circles_images');

      if (homeHeroImages.is(':visible')) {
        homeHeroImages.slick({
          autoplay: true,
          autoplaySpeed: 5000,
          arrows: false,
          asNavFor: '.home_hero_no_circles_content_container',
          pauseOnHover: true
        });

        $('.home_hero_no_circles_content_container').slick({
          autoplay: true,
          autoplaySpeed: 5000,
          arrows: false,
          asNavFor: '.home_hero_no_circles_images',
          pauseOnHover: true
        });
      }

      // testimonials
      $('.testimonial_images').slick({
        slidesToShow: 3,
        arrows: false,
        asNavFor: '.testimonial_text',
        centerMode: true,
        autoplay: true,
        autoplaySpeed: 5000,
        variableWidth: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              variableWidth: false
            }
          }
        ]
      });

      $('.testimonial_text').slick({
        slidesToShow: 1,
        arrows: false,
        asNavFor: '.testimonial_images',
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true
      });

      // locations
      $('.locations').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 5000,
        prevArrow: '<div class="prev_arrow location_arrow"></div>',
        nextArrow: '<div class="next_arrow location_arrow"></div>',
        responsive: [
          {
            breakpoint: 1070,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false
            }
          }
        ]
      });

    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
